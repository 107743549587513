import React from 'react';
import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';

const EHSIntro = () => {
    return (
        <>
            <div className="m-auto overflow-hidden mx-4 lg:mt-12 p-2 md:p-12 h-5/6" data-aos="zoom-in">

                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-5 text-3xl font-bold text-blue-900">
                            {/* We build digital solutions to help businesses scale */}
                                Electrical safety is paramount, and Reliable EHS offers a suite of services to ensure the highest standards for electrical and other safety.
                            </h1>
                            <div className="text-xl font-semibold tracking-tight mb-5 mt-8 text-gray-500">Our Electrical Safety Management services include Electrical Safety Audits, Arc Flash Studies, E-Arc Studies, Lightning Protection Risk Assessments, Thermography Audits, E-HAZOP (Hazard and Operability Study for Electrical Systems), and the Review of Hazardous Area Classification. Rely on our expertise to safeguard your organization against electrical hazards and ensure compliance with industry regulations.
</div>
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Contact Us
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                                {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Get Started
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link> */}
                                {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn More
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                                </a> */}
                            </div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/2 ml-4" data-aos="fade-up" data-aos-delay="700">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" src="https://media.licdn.com/dms/image/D4D12AQErM3AeKm81qw/article-cover_image-shrink_600_2000/0/1658560937811?e=2147483647&v=beta&t=CONZRaiYH4EZHVcjbXBcywCOec8zyDx_HHYDSZFkBKQ" />
                        </div>
                </div>
                
                <div className="mt-8 px-6 py-4 border border-gray-200 rounded shadow-sm">
                    <h2 className="text-2xl font-bold text-blue-900 mb-4">Our Services include:</h2>
                    <div className="grid md:grid-cols-3 gap-4 text-gray-700">
                        <div><li>Electrical Safety Audit</li></div>
<div><li>Arc Flash Study</li></div>
<div><li>E Arc Study</li></div>
<div><li>Lightning Protection Risk Assessment</li></div>
<div><li>Thermography Audit</li></div>
<div><li>E HAZOP</li></div>
<div><li>Review of Hazardous Area Classification</li></div>
<div><li>Traffic and Logistics Safety Management</li></div>
<div><li>Development of Forklift, Vehicular Safety Manual</li></div>
<div><li>Warehouse Safety Audit and Management</li></div>
<div><li>Warehouse Layout Design</li></div>
<div><li>Certification for Competency of Payloader, Forklift, and Other Vehicles</li></div>
<div><li>Legal Compliances and Training of Hazardous Chemical Handling</li></div>
<div><li>Educational Building</li></div>
<div><li>Institutional Building</li></div>
<div><li>Business and Commercial Building</li></div>
<div><li>Hazardous Building</li></div>
<div><li>Storage Building</li></div>
<div><li>Scaffolding Testing</li></div>

                    </div>
                </div>

            </div>


        </>
    )
}

export default EHSIntro;