import React from 'react';
import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKmJLaCJRcvFjtqW_npD_0osL7gwnBDFNCe7U_d0v_9q-EqRCA-kNplIJB8w2AXQsrl_w&usqp=CAU" />
                        </div>
                        <div className="flex-col my-4 text-center lg:text-left lg:my-4 lg:justify-end w-full lg:w-2/3 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-2xl  text-blue-900 font-bold"> Pioneers in providing a comprehensive and integrated suite of health, safety, and environmental services under one roof, with a vision to protect workers, assets and community in the most cost effective and structured manner.</h3>
                            <div>
                                <p className='my-3 text-l text-gray-600 font-semibold'>Our consultants, who have domain expertise in health, safety and environment issues, are always at your service not only to help achieve best practices within your organizations but also improve the current Standard Operating Procedures that an organization follows and support in implementation.</p>
                            </div>
                            
                            {/* <div>
                                <p className='my-3 text-l text-gray-600 font-semibold'>We take responsibility for building custom software solutions that caters for automation of your business processes and improve efficiency.</p>
                            </div> */}
                            <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Contact us
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Intro;