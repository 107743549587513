import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import EHSIntro from '../components/accidentPrevention';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import DemoProduct from './DemoProduct';

const AccidentPrevention = (props) => {

    useDocTitle('Reliable EHS | AccidentPrevention');

    return (
        <>
            <div>
                <NavBar />
            </div>
            <EHSIntro />
            <DemoProduct />
            <Footer />
        </>


    )
}

export default AccidentPrevention;