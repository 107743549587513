import React from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';
import img5 from '../images/img5.svg';
import img6 from '../images/professional.svg';
import img7 from '../images/img7.svg';
import img8 from '../images/img8.svg';
import img9 from '../images/img9.svg';
import { Link } from 'react-router-dom';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">We are deeply committed to the growth and success of our clients.</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            
                            {/* Safety training */}
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center">
                                <img alt="card img" className="rounded-t w-32 h-32 group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Safety Training</h2>
                                    <p className="text-md font-medium">
                                        At Reliable EHS, we understand that a well-trained workforce is crucial for maintaining a safe and secure work environment. 
                                    </p>
                                </div>
                                <div className="flex justify-center my-4">
                                    <Link to="/Ehs" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Know more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>

                            </div>

                            {/* Process Safety Consulting services */}
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center">
                                <img alt="card img" className="rounded-t w-32 h-32 group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Process Safety Consulting services</h2>
                                    <p className="text-md font-medium">
                                        At Reliable EHS, we offer Process Safety Consulting services that go beyond compliance to proactively identify and mitigate risks.
                                    </p>
                                </div>
                            
                                <div className="flex justify-center my-4">
                                    <Link to="/ProcessSafety" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Know more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>

                            {/* E learning and AR/VR */}
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center">
                                    <img alt="card img" className="rounded-t w-32 h-32 group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">E-learning and AR/VR</h2>
                                    <p className="text-md font-medium">
                                        Embrace innovative learning methodologies with Reliable EHS's E Learning and AR/VR services.
                                    </p>
                                </div>
                                
                                <div className="flex justify-center my-4">
                                    <Link to="/ELearning" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Know more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>

                            {/* Software portfolio */}
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center">
                                <img alt="card img" className="rounded-t w-32 h-32 group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Software Portfolio</h2>
                                    <p className="text-md font-medium">
                                        Explore our comprehensive Software Portfolio designed to streamline EHS management at every level.
                                    </p>
                                </div>

                                <div className="flex justify-center my-4">
                                    <Link to="/SoftwarePortfolio" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Know more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            
                        </div> 
                        
                        {/*  Safety Management system */}
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center">
                                <img alt="card img" className="rounded-t w-32 h-32 group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img5} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Safety Management system</h2>
                                    <p className="text-md font-medium">
                                        Reliable EHS specializes in the effective implementation of Safety Management Systems tailored to your organization's unique needs.
                                    </p>
                            </div>
                            <div className="flex justify-center my-4">
                                    <Link to="/SafetyManagement" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Know more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                        </div>
                        
                        {/* Accident Prevention & Management */}
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center">
                                <img alt="card img" className="rounded-t w-32 h-32 group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img6} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Accident Prevention & Management</h2>
                                    <p className="text-md font-medium">
                                        Prioritize safety with Reliable EHS's Accident Prevention & Management services. 
                                    </p>
                            </div>
                            <div className="flex justify-center my-4">
                                    <Link to="/AccidentPrevention" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Know more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                        </div>

                        {/* Capability & Competency development */}
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center">
                                <img alt="card img" className="rounded-t w-32 h-32 group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img7} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Capability & Competency</h2>
                                    <p className="text-md font-medium">
                                        Invest in the growth of your workforce with Reliable EHS's Capability & Competency Development services.
                                    </p>
                            </div>
                            <div className="flex justify-center my-4">
                                    <Link to="/CapabilityDevelopment" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Know more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                        </div>
                        
                        {/* Culture Transformation */}
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center">
                                <img alt="card img" className="rounded-t w-32 h-32 group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img8} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Culture Transformation</h2>
                                    <p className="text-md font-medium">
                                    Transform your organizational culture with Reliable EHS's Culture Transformation services.
                                    </p>
                            </div>
                            <div className="flex justify-center my-4">
                                    <Link to="/CultureTransformation" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Know more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                        </div>
                        
                        {/* Electrical safety management */}
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center">
                                <img alt="card img" className="rounded-t w-32 h-32 group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img9} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Electrical, Traffic, Construction and Building safety management</h2>
                                    <p className="text-md font-medium">
                                        Electrical safety is paramount, and Reliable EHS offers a suite of services to ensure the highest standards.
                                    </p>
                            </div>
                            <div className="flex justify-center my-4">
                                    <Link to="/ElectricalSafetyManagement" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Know more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                        </div>
                        
                        
                        </div>
                        
                    </div>
            </section>

            <section>
                <div className="mt-32 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our belief</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        {/* <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">We are deeply committed to the growth and success of our clients.</h2> */}
                    </div>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        {/* Missiom */}
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            {/* <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                            </div> */}
                            <h3 className="text-3xl  text-blue-900 
                            font-bold"><span className='font-black'>Mission</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    We provides reliable, sustainable, innovative , tailored and cost effective safety, Health and Environment solution to build a better, safer and Healthier EHS culture for targeted client to operate and grow business.
                                </p>
                            </div>
                        </div>

                        {/* Vision */}
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            {/* <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div> */}
                            <h3 className="text-3xl  text-blue-900 font-bold"><span className='font-black'>Vision</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>To be recognized as leader in Safety, Health and Environment domain by providing  trustworthy ,innovative and value added EHS services which build reliability for our customers.</p>
                            </div>
                        </div>

                        {/* Core values */}
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            {/* <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div> */}
                            <h3 className="text-3xl  text-blue-900 font-bold"><span className='font-black'>Core Values</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Operate with integrity in line with our value, work with our clients and all associates with respect and empathy to save lives, Environment and create sustainable future.
Strive for Safety excellence through creativity and innovation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-grey py-8 mt-12">
                <div className="container mx-auto px-4">
                    <h2 className="text-center text-4xl text-blue-900 font-bold uppercase mb-6">About Reliable EHS Services</h2>
                    <div className="border-b-4 border-blue-900 w-24 mx-auto mb-6"></div>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        RELIABLE EHS Services is a subsidiary company of Reliable SDS multi services Private Limited, established in 2012. Our prime aim is to create awareness and provide consultancy on Safety, Health, and Environment in various industrial sectors. We impart Safety training among workers and staff exposed to occupational hazards and risks of fatal accidents at their workplace.
                        Our goal includes training safety professionals, enabling them with the skills and knowledge to respond confidently and competently to accidents.
                    </p>
                    <p className="text-lg text-gray-700 mt-4 leading-relaxed">
                        We are Pioneers in providing a comprehensive and integrated suite of health, safety, and environmental services. Our vision is to protect workers, assets, and the community in the most cost-effective and structured manner, serving as a ‘one-stop solution provider’ for health, safety, and environmental needs. Our consultants, with domain expertise, help achieve best practices and improve Standard Operating Procedures, supporting implementation.
                    </p>
                    <div className="mt-6">
                        <h3 className="text-xl text-blue-900 font-semibold">Contact Information:</h3>
                        <ul className="list-disc ml-5 text-gray-600">
                            <li>Website: <a href="https://reliable-ehs.com" className="text-blue-900 underline">reliable-ehs.com</a></li>
                            <li>Industry: Public Safety</li>
                            <li>Company Size: 1-10 Employees</li>
                            <li>Founded: In 2012</li>
                            <li>Specialities: Occupational Safety, Health and Environment Training, EHS Audit, Safety Software’s, E-learning modules, SOP development, Risk assessment, PSM, Gap assessment audit, BBS audit and implementation, Complete solutions in safety.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;